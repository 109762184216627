import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NotFoundError = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div>
            <div className='d-flex h-screen w-full flex-column align-items-center justify-content-center gap-2'>
                <h1 className='display-1 fw-bolder'>404</h1>
                <span className='fw-semibold'>{t('not_found_title')}</span>
                <div className='mt-6 d-flex gap-4'>
                    <button className='btn btn-light' onClick={() => navigate(-1)}>
                        {t('not_found_back')}
                    </button>
                    <button className='btn btn-gradient-primary' onClick={() => navigate('/')}>
                        {t('not_found_home')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NotFoundError