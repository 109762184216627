import axios from 'axios';

let instance = null;
instance = axios.create({
    baseURL: 'https://apiweb.hochay.edu.vn/api/',
    // baseURL: 'http://42.119.236.231:2234/api/',
});

// INTERCEPTORS CONFIG END

const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
};
const getLocalStorage = (key) => {
    return localStorage.getItem(key);
};

const removeTokenFromLocalStorage = (key) => {
    return localStorage.removeItem(key);
}

const clearLocalStorage = () => {
    localStorage.clear();
};


//getAPI Area ==========================
async function getApi(url, searchModel) {
    const token = getLocalStorage('accessToken');

    try {
        let fullUrl = url;
        const queryParams = [];

        for (const key in searchModel) {
            if (searchModel.hasOwnProperty(key)) {
                const value = searchModel[key];
                if (value !== undefined && value !== null) {
                    queryParams.push(`${key}=${encodeURIComponent(value)}`);
                }
            }
        }

        if (queryParams.length > 0) {
            fullUrl += `?${queryParams.join('&')}`;
        }

        const res = await instance.get(fullUrl, {
            headers: {
                Authorization: token ? `Bearer ${token}` : 'no auth',
            },
        });

        return res;
    } catch (err) {
        return err;
    }
}



async function getApiV2(url, searchModel, type) {
    const token = getLocalStorage('accessToken');

    try {
        let fullUrl = url;
        const queryParams = [];

        for (const key in searchModel) {
            if (searchModel.hasOwnProperty(key)) {
                const value = searchModel[key];
                if (value !== undefined && value !== null) {
                    queryParams.push(`${key}=${encodeURIComponent(value)}`);
                }
            }
        }

        if (queryParams.length > 0) {
            fullUrl += `?${queryParams.join('&')}`;
        }

        const res = await instance.get(fullUrl, {
            responseType: type,
            headers: {
                Authorization: token ? `Bearer ${token}` : 'no auth',
            },
        }
        );

        return res;
    } catch (err) {
        return err;
    }
}

// POST API AREA ============================>
async function postApi(url, payload, file) {
    try {
        const res = await instance.post(url, payload, {
            headers: {
                Authorization: getLocalStorage('accessToken') ? `Bearer ${getLocalStorage('accessToken')}` : 'no-author',
                'Content-Type': file ? 'multipart/form-data' : 'application/json; charset=utf-8',
                'Access-Control-Allow-Headers':
                    'Content-Type, X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Date, X-Api-Version, X-File-Name',
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Origin': '*',
            },
        });
        return res;
    } catch (err) {
        return err;
    }
}

async function postApi2(url, payload, file) {
    try {
        const res = await instance.post(url, payload, {
            headers: {
                Authorization: getLocalStorage('accessToken') ? `Bearer ${getLocalStorage('accessToken')}` : 'no-author',
                'Access-Control-Allow-Headers':
                    'Content-Type, X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Date, X-Api-Version, X-File-Name',
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Origin': '*',
            },
        });
        return res;
    } catch (err) {
        return err;
    }
}

// Delete
async function deleteApi(url) {
    try {
        const res = await instance.delete(`/${url}`, {
            headers: {
                Authorization: getLocalStorage('accessToken') ? `Bearer ${getLocalStorage('accessToken')}` : 'no-author',
            },
        });
        return res;
    } catch (err) {
        return err;
    }
}

// PUT API AREA ============================>
async function putApi(url, payload) {
    try {
        const res = await instance.put(`/${url}`, payload, {
            headers: {
                Authorization: getLocalStorage('accessToken') ? `Bearer ${getLocalStorage('accessToken')}` : 'no-author',
            },
        });
        return res;
    } catch (err) {
        return err;
    }
}

const ClientId = '7452936354-s4qmrvmm0eqirhpldh2tu3so523s8rmq.apps.googleusercontent.com';
const FacebookAppId = '3714972065499630';
const FacebookPageId = '61557114767039';

export {
    postApi,
    getApi,
    getApiV2,
    postApi2,
    putApi,
    deleteApi,
    setLocalStorage,
    getLocalStorage,
    clearLocalStorage,
    removeTokenFromLocalStorage,
    ClientId,
    FacebookAppId,
    FacebookPageId
};