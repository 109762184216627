import React, { createContext, useContext, useEffect, useState } from 'react';
import { clearLocalStorage, getLocalStorage } from '../services/agent';
import UserService from '../services/UserService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {
        const initAuth = async () => {
            const token = getLocalStorage("accessToken")

            if (token) {
                const response = await UserService.checkTokenExpiration(token)

                if (response.data?.isExpired === false) {
                    setIsAuthenticated(true)
                } else {
                    logout();
                }
            } else {
                logout();
            }
        }

        initAuth()
    }, []);

    const login = async () => {
        try {
            setIsAuthenticated(true)
        } catch (error) {
            setIsAuthenticated(false)
            throw error
        }
    }

    const logout = () => {
        clearLocalStorage();
        sessionStorage.removeItem("accessToken");
        setIsAuthenticated(false)
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
