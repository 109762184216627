import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';
import viTranslation from './translations/vi.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: enTranslation },
            vi: { translation: viTranslation }
        },
        lng: 'vi', // Default language
        fallbackLng: 'vi', // Fallback language
        interpolation: {
            escapeValue: false // React already sanitizes content
        }
    });

export default i18n;