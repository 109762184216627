import { getApi, postApi } from "./agent";

const UserService = {
    Register: async (registrationData) => {
        try {
            let result = await postApi('users/register', registrationData);
            return result;
        } catch (error) {
            console.error(error);
        }
    },
    Login: async (loginData) => {
        try {
            let result = await postApi('users/login-via-form', loginData);
            return result;
        } catch (error) {
            console.error(error);
        }
    },

    LoginViaGoogle: async (data) => {
        try {
            let result = await postApi('users/login-via-google', data);
            return result;
        } catch (error) {
            console.error(error);
        }
    },

    checkTokenExpiration: async (data) => {
        try {
            const result = await postApi('users/check-token-expiration', data)
            return result;
        } catch (err) {
            console.error(err)
        }
    },

    deposit: async (amount) => {
        try {
            const result = await postApi(`deposit?amount=${amount}`);
            return result;
        } catch (error) {
            console.error(error);
        }
        return null;
    },

    getPaymentInfo: async () => {
        try {
            let result = await getApi("deposit/admin-payment-info");
            return result;
        } catch (error) {
            console.error(error);
        }
    },
    getAllGrades: async () => {
        try {
            let result = await getApi("grades");
            return result;
        } catch (error) {
            console.error(error);
        }
    },

    getAllProvinces: async () => {
        try {
            let result = await getApi("provinces");
            return result;
        } catch (error) {
            console.error(error);
        }
    },

    getDistrictByProvinceId: async (provinceId) => {
        try {
            let result = await getApi(`districts?provinceId=${provinceId}`);
            return result;
        } catch (error) {
            console.error(error);
        }
    },

    getAllSchools: async (districtId) => {
        try {
            let result = await getApi(`schools?districtId=${districtId}`);
            return result;
        } catch (error) {
            console.error(error);
        }
    },
}

export default UserService