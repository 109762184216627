import { createBrowserRouter } from 'react-router-dom'
import NotFoundError from '../pages/NotFound/NotFoundError'
import ProtectedRoute from './ProtectedRoute'

const router = createBrowserRouter([
    {
        path: '/login',
        lazy: async () => ({
            Component: (await import('../pages/Login/Login')).default,
        }),
    },
    {
        path: '/register',
        lazy: async () => ({
            Component: (await import('../pages/Register/Register')).default,
        }),
    },
    {
        path: '/course/:courseId/:slugCourse/lesson/:lessonId/theory/:resourceId',
        lazy: async () => ({
            Component: (await import('../pages/Lesson/Lesson')).default,
        }),
    },
    {
        path: '/course/:courseId/:slugCourse/lesson/:lessonId/theory/:resourceId',
        lazy: async () => ({
            Component: (await import('../pages/Lesson/Lesson')).default,
        }),
    },
    {
        path: '/course/:courseId/:slugCourse/lesson/:lessonId/exercise/:exerciseId',
        lazy: async () => ({
            Component: (await import('../pages/Exercise/Exercise')).default,
        }),
    },
    {
        path: '/book/:id/:slug/read',
        lazy: async () => ({
            Component: (await import('../pages/DocumentStorage/BookDetails/ReadBook')).default,
        }),
    },
    {
        path: '/online-exams',
        element: <ProtectedRoute />,
        children: [
            {
                index: true,
                lazy: async () => ({
                    Component: (await import('../pages/OnlineExam/OnlineExam')).default,
                }),
            },
            {
                path: ':id/:slug',
                lazy: async () => ({
                    Component: (await import('../pages/OnlineExamDetail/OnlineExamDetail')).default,
                }),
            },
            {
                path: 'leader-board/:id',
                lazy: async () => ({
                    Component: (await import('../pages/OnlineExam/LeaderboardPage/LeaderboardPage')).default,
                }),
            },
            {
                path: 'profile',
                lazy: async () => ({
                    Component: (await import('../pages/OnlineExam/ProfileOnlineExamPage/ProfileOnlineExamPage')).default,
                }),
            },
        ],
    },
    {
        path: '/:linkBack/student-exam-done/:id',
        element: <ProtectedRoute />,
        children: [
            {
                index: true,
                lazy: async () => ({
                    Component: (await import('../pages/StudentExam/StudentExamDone')).default,
                }),
            },
        ],
    },

    // Have layout routes
    {
        path: '/',
        lazy: async () => {
            const AppShell = await import('../layout/AppShell')
            return { Component: AppShell.default }
        },
        children: [
            {
                index: true,
                lazy: async () => ({
                    Component: (await import('../pages/Homepage/HomePage')).default,
                }),
            },
            {
                path: 'introduce',
                children: [
                    {
                        index: true,
                        lazy: async () => ({
                            Component: (await import('../pages/Introduce/Introduce')).default,
                        }),
                    },
                    {
                        path: ':slug',
                        lazy: async () => ({
                            Component: (await import('../pages/Introduce/Introduce')).default,
                        }),
                    },
                ],
            },
            {
                path: 'teacher-detail',
                lazy: async () => ({
                    Component: (await import('../pages/TeacherDetail')).default,
                }),
            },
            {
                path: 'contact',
                lazy: async () => ({
                    Component: (await import('../pages/ContactUs/contact')).default,
                }),
            },
            {
                path: 'course',
                lazy: async () => ({
                    Component: (await import('../pages/Course/Course')).default,
                }),
            },
            {
                path: 'course?page=:indexPage',
                lazy: async () => ({
                    Component: (await import('../pages/Course/Course')).default,
                }),
            },
            {
                path: 'course/:id/:slug',
                lazy: async () => ({
                    Component: (await import('../pages/CourseDetail/courseDetail')).default,
                }),
            },
            {
                path: 'course/:courseId/:slugCourse/test/:id',
                lazy: async () => ({
                    Component: (await import('../pages/CourseTestDetail/CourseTestDetail')).default,
                }),
            },
            {
                path: 'news-and-event',
                lazy: async () => ({
                    Component: (await import('../pages/NewsAndEvent/NewsAndEvent')).default,
                }),
            },
            {
                path: 'news-and-event/:id/:slug',
                lazy: async () => ({
                    Component: (await import('../pages/NewsDetail/Index')).default,
                }),
            },
            {
                path: 'test-document',
                children: [
                    {
                        index: true,
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Documents/Documents')).default,
                        }),
                    },
                    {
                        path: ':grade',
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Documents/Documents')).default,
                        }),
                    },
                    {
                        path: ':grade?page=:indexPage',
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Documents/Documents')).default,
                        }),
                    },
                    {
                        path: ':grade/:category',
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Documents/Documents')).default,
                        }),
                    },
                    {
                        path: ':grade/:category?page=:indexPage',
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Documents/Documents')).default,
                        }),
                    },
                ],
            },
            {
                path: 'material/:id/:slug',
                lazy: async () => ({
                    Component: (await import('../pages/DocumentStorage/DocumentDetail/DocumentDetail')).default,
                }),
            },
            {
                path: 'achievement',
                lazy: async () => ({
                    Component: (await import('../pages/Achievement')).default,
                }),
            },
            {
                path: 'question-answer',
                lazy: async () => ({
                    Component: (await import('../pages/Faq/Faq')).default,
                }),
            },
            {
                path: 'admissions',
                lazy: async () => ({
                    Component: (await import('../pages/Admissions')).default,
                }),
            },
            {
                path: 'book',
                children: [
                    {
                        index: true,
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Books/Books')).default,
                        }),
                    },
                    {
                        path: ':grade',
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Books/Books')).default,
                        }),
                    },
                    {
                        path: ':grade?indexPage=:indexPage',
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Books/Books')).default,
                        }),
                    },
                    {
                        path: ':grade/:category',
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Books/Books')).default,
                        }),
                    },
                    {
                        path: ':grade/:category?page=:indexPage',
                        lazy: async () => ({
                            Component: (await import('../pages/DocumentStorage/Books/Books')).default,
                        }),
                    },
                ],
            },
            {
                path: 'book-detail/:id/:slug',
                lazy: async () => ({
                    Component: (await import('../pages/DocumentStorage/BookDetails/BookDetails')).default,
                }),
            },
            {
                path: 'profile',
                element: <ProtectedRoute />,
                children: [
                    {
                        index: true,
                        lazy: async () => ({
                            Component: (await import('../pages/Profile/Profile')).default,
                        }),
                    },
                ],
            },
            {
                path: 'schedule',
                lazy: async () => ({
                    Component: (await import('../pages/Schedule/Schedule')).default,
                }),
            },
        ],
    },

    // Fallback 404 route
    { path: '*', Component: NotFoundError },
])

export default router
